import React, { useState, useEffect } from "react";
import "../PagesStyles/AdminHome.css";
import AdminBarChart from "./AdminComponent/AdminBarChart";
import AdminDailyRatesTable from "./AdminComponent/AdminDailyRatesTable";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import AdminPieChart from "./AdminComponent/AdminPieChart";
import AdminSideBar from "./AdminComponent/AdminSideBar";
import AdminProducts from "./AdminProducts";
import { useSelector } from "react-redux";
import { AiFillGold, AiOutlineGold } from "react-icons/ai";
import { MdBorderColor, MdOutlinePendingActions } from "react-icons/md";

export default function AdminHome() {
  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>

      <div
        className={
          sidebarState
            ? "adminContentbox AdminGridContainer"
            : "adminContentbox adminContentbox2 AdminGridContainer"
        }

        //   className="adminContentbox AdminGridContainer"
      >
        <h2 style={{ paddingTop: "1rem" }}>Dashboard</h2>
        <div className="row">
          <div
            className="col-3"
            // style={{ backgroundColor: "rgb(100,100,250)" }}
          >
            <div className="adminMainPageHomeItems">
              <div
                className="adminHomePageMainLogos"
                style={{
                  backgroundColor: "rgb(137, 146, 240)",
                  border: "2px solid rgb(49, 65, 235)",
                }}
              >
                <AiFillGold color="rgb(49, 65, 235)" size={"3rem"} />
              </div>
              <p>ONLINE SALE</p>
            </div>
            <div className="adminMainPageHomeItems">
              <h2>₹ 10000</h2>
              <h4>Sale</h4>
            </div>
          </div>
          <div className="col-3">
            <div className="adminMainPageHomeItems">
              <div
                className="adminHomePageMainLogos"
                style={{
                  backgroundColor: "rgb(127, 235, 164)",
                  border: "2px solid rgb(4, 179, 63)",
                }}
              >
                <AiOutlineGold color="rgb(4, 179, 63)" size={"3rem"} />
              </div>
              <p>BRANCH SALE</p>
            </div>
            <div className="adminMainPageHomeItems">
              <h2>₹ 10000</h2>
              <h4>Sale</h4>
            </div>
          </div>
          <div className="col-3">
            <div className="adminMainPageHomeItems">
              <div
                className="adminHomePageMainLogos"
                style={{
                  backgroundColor: "rgb(119, 217, 212)",
                  border: "2px solid rgb(1, 145, 139)",
                }}
              >
                <MdBorderColor color="rgb(1, 145, 139)" size={"2rem"} />
              </div>
              <p>ORDERS</p>
            </div>
            <div className="adminMainPageHomeItems">
              <h2>15</h2>
              <h4>Customized</h4>
            </div>
          </div>
          <div className="col-3">
            <div className="adminMainPageHomeItems">
              <div
                className="adminHomePageMainLogos"
                style={{
                  backgroundColor: "rgb(255, 167, 99)",
                  border: "2px solid rgb(204, 89, 0)",
                }}
              >
                <MdOutlinePendingActions
                  color="rgb(204, 89, 0)"
                  size={"2rem"}
                />
              </div>
              <p>PENDING</p>
            </div>
            <div className="adminMainPageHomeItems">
              <h2>12</h2>
              <h4>Deliveries</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6" style={{ flexBasis: "70%" }}>
            {/* <div className="adminDashBoardChartBox"> */}
            <div className="adminDashboardBarChart">
              <AdminBarChart />
            </div>
            {/* </div> */}
          </div>
          <div className="col-6">
            <div className="adminDailyRateBox">
              <AdminDailyRatesTable />
            </div>
          </div>
        </div>
        <h2 style={{ margin: "1rem 0" }}>Recent Orders</h2>
        <div className="row">
          <div className="col-12">Recent Orders Box</div>
        </div>
      </div>
    </div>
  );
}
{
  /* <div className="adminDashboardPieChart">
  <AdminPieChart />
</div> */
}
