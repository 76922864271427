import React, { useEffect, useState } from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import {
  a16,
  a18,
  a20,
  a22,
  a24,
  a28,
  a30,
  a31,
  a33,
  a41,
  a43,
  a47,
  a8,
} from "../../Api/RootApiPath";
import { BsImages } from "react-icons/bs";
import MyPDF from "./AdminComponent/AdminProductLabel";
// import AdminOpenLabel from "./AdminComponent/AdminOpenLabel";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import { InfinitySpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import AlertMessage from "../../AlertMessages/AlertMessage";
// import { app } from "../../../firebase";
// import firebase from "firebase/app";
// // import "firebase/database";
// import { getDatabase } from "firebase/database";

// import { initializeApp } from "firebase/app";
// import { getDatabase } from "firebase/database";
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  onValue,
  off,
  set,
  update,
  push,
} from "firebase/database";

// const database = getDatabase();

export default function AdminAddBulkStock() {
  const [qr, setQr] = useState("");
  const [productName, setProductName] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  //   const [categoryId, setCategoryId] = useState("");
  const [pieces, setPieces] = useState(1);
  const [huid, setHuid] = useState("");
  const [netWt, setNetWt] = useState(0);
  const [size, setSize] = useState(0);
  const [grosswt, setGrosswt] = useState(0);
  const [purity, setPurity] = useState("");
  const [collection, setCollection] = useState("");
  const [occasion, setOccasion] = useState("");
  const [gender, setGender] = useState("");
  const [description, setDescription] = useState("");
  // const [productTypeId, setProductTypeId] = useState("");
  const [partyTypeId, setPartyTypeId] = useState("");
  const [boxId, setBoxId] = useState(1);
  const [making_per_gram, setMaking_per_gram] = useState(0);
  const [making_Fixed_Amt, setMaking_Fixed_Amt] = useState(0);
  const [making_Percentage, setMaking_Percentage] = useState(0);
  const [making_Fixed_Wastage, setMaking_Fixed_Wastage] = useState(0);
  const [stoneWeight, setStoneWeight] = useState(0);
  const [stoneAmount, setStoneAmount] = useState(0);
  const [featured, setFeatured] = useState("");
  const [productCode, setProductCode] = useState("");
  const [mrp, setMRP] = useState(0);
  const [itemCode, setItemCode] = useState("");
  const [itemType, setItemType] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [category, setCategory] = useState("");
  const [productTypeData, setProductTypeData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [purityData, setPurityData] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [collectionTypeData, setCollectionTypeData] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [parameter, setParameter] = useState("");
  const [formValue, setFormValue] = useState("");
  const [productType, setProductType] = useState("");
  const [productInEdit, setProductInEdit] = useState([]);
  const [productInEditImages, setProductInEditImages] = useState();
  const [updatedProducts, setUpdatedProducts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const [goldAlert, setGoldAlert] = useState(false);
  const [barCodeAlert, setBarCodeAlert] = useState(false);
  const [firebaseData, setFirebaseData] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  const [rifdData, setRifdData] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [selectedItemCodes, setSelectedItemCodes] = useState([]);
  const [allItemCodesArray, setAllItemCodesArray] = useState([]);
  const [deleteAll, setDeleteAll] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(false);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    fetch(a18)
      .then((res) => res.json())
      .then((data) => setCategoriesData(data.data));
  }, []);
  useEffect(() => {
    fetch(a20)
      .then((x) => x.json())
      .then((y) => setProductTypeData(y.data));
  }, []);
  // console.log(productTypeData.category_id);

  useEffect(() => {
    fetch(a22)
      .then((res) => res.json())
      .then((data) => setPurityData(data.data));
    // console.log(purityData);
  }, []);
  useEffect(() => {
    fetch(a28)
      .then((res) => res.json())
      .then((data) => setPartyData(data.data.result));
  }, []);
  useEffect(() => {
    fetch(a33)
      .then((res) => res.json())
      .then((data) => setCollectionTypeData(data.data));
  }, []);
  // console.log(partyData);
  useEffect(() => {
    fetch(a30)
      .then((res) => res.json())
      .then((data) => setBoxData(data.data));
  }, []);

  // const fetchAllProductsData = async () => {
  //   await fetch(a41)
  //     .then((res) => res.json())
  //     .then((response) => {
  //       setAllProducts(response.data);
  //       setLoadingAdd(true);
  //     });
  // };

  // useEffect(() => {
  //   fetchAllProductsData();
  // }, []);

  let categoryId = parseInt(category.split(",")[0]);
  let categoryName = category.split(",")[1];
  let productTypeId = parseInt(productType.split(",")[0]);
  let productTypeName = productType.split(",")[1];
  let collectionId = parseInt(collection.split(",")[0]);
  let collectionName = collection.split(",")[1];
  let purityId = parseInt(purity.split(",")[0]);
  let purityName = purity.split(",")[1];
  let partyId = parseInt(partyTypeId.split(",")[0]);
  let partyName = partyTypeId.split(",")[1];

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 > files.length > 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };

  useEffect(() => {
    fetch(a43)
      .then((res) => res.json())
      .then((data) => setRifdData(data.data)),
      setLoadingAdd(true);
  }, []);

  console.log(rifdData);
  // useEffect(() => {
  //   const productsRef = firebase.database().ref("products");

  //   // Attach a listener to 'value' event to fetch the data
  //   productsRef.on("value", (snapshot) => {
  //     const data = snapshot.val();
  //     // Convert the data object to an array if needed
  //     const productsArray = data ? Object.values(data) : [];
  //     setFirebaseProducts(productsArray);
  //   });

  //   // Unsubscribe the listener when the component unmounts
  //   return () => {
  //     productsRef.off();
  //   };
  // }, []);

  // const database = firebase.database();

  // const firebaseConfig = {
  //   // Your configuration details here
  //   apiKey: "AIzaSyDerNHPJwykR6GA6H_tMb7p-eMdQ8uR13k",
  //   authDomain: "loyalstrings-3c53b.firebaseapp.com",
  //   databaseURL: "https://loyalstrings-3c53b-default-rtdb.firebaseio.com",
  //   projectId: "loyalstrings-3c53b",
  //   storageBucket: "loyalstrings-3c53b.appspot.com",
  //   messagingSenderId: "423371897105",
  //   appId: "1:423371897105:web:5cd4266256c8722a6034b0",
  //   measurementId: "G-GZGQ6XMDSJ",
  // };

  // const app = initializeApp(firebaseConfig);
  // const database = getDatabase(app);
  // const dataref = ref(database, "tagdata"); // Use the correct reference name here
  // const dataref1 = ref(database, "testdata");
  // const dataref2 = ref(database, "testdata");

  // useEffect(() => {
  //   // Call the writeDataToFirebase function when the component mounts
  //   writeDataToFirebase();
  // }, []);

  // const updateDataToFirebase = () => {
  //   console.log("hitted save");

  //   addedProducts.forEach((product) => {
  //     // Prepare the data you want to update for each product
  //     const updatedData = {
  //       tidvalue: product.tid,
  //       barcode: product.barcodeNumber.toUpperCase(),
  //     };

  //     update(ref(dataref1, product.id), updatedData)
  //       .then(() => {
  //         console.log(`Data updated to Firebase for product ${product.id}!`);
  //       })
  //       .catch((error) => {
  //         console.error(
  //           `Error updating data to Firebase for product ${product.id}:`,
  //           error
  //         );
  //       });
  //   });
  // };
  // const writeDataToFirebase = (data) => {
  //   // The data you want to write to the database
  //   console.log("hitted save");
  //   const newData = {
  //     tidvalue: "value1",
  //     barcode: "value2",
  //     // Add more key-value pairs as needed
  //   };

  //   data.forEach((x) => {
  //     // Write the data for each item in addedProducts array
  //     set(dataref1, x)
  //       .then(() => {
  //         console.log("Data successfully written to Firebase!");
  //       })
  //       .catch((error) => {
  //         console.error("Error writing data to Firebase: ", error);
  //       });
  //   });
  // };
  // const writeDataToFirebase = (data) => {
  //   const database = getDatabase(app);
  //   const dataref = ref(database, "testdata");
  //   data.forEach((product) => {
  //     const newProductRef = ref.push(dataref);
  //     set(newProductRef, product)
  //       .then(() => {
  //         console.log("Data successfully written to Firebase!");
  //       })
  //       .catch((error) => {
  //         console.error("Error writing data to Firebase: ", error);
  //       });
  //   });
  // };
  // onValue(dataref, (snapshot) => {
  //   const data2 = snapshot.val();
  //   // Do something with the data
  //   console.log(data2);
  //   setFirebaseData(data2);
  // });

  // useEffect(() => {
  //   setIsMounted(true);
  //   fetchData();

  //   return () => {
  //     setIsMounted(false);
  //   };
  // }, []);

  // const fetchData = () => {
  //   onValue(dataref, (snapshot) => {
  //     const data2 = snapshot.val();
  //     console.log(data2);
  //     setFirebaseData(data2);
  //   });
  // };

  // useEffect(() => {
  //   if (isMounted) {
  //     onValue(dataref, (snapshot) => {
  //       const data2 = snapshot.val();
  //       setFirebaseData(data2);
  //     });
  //   } else {
  //     off(dataref);
  //   }
  // }, [isMounted]);

  // function writeUserData(userId, name, email, imageUrl) {
  //   const db = getDatabase();
  //   set(ref(db, 'users/' + userId), {
  //     username: name,
  //     email: email,
  //     profile_picture : imageUrl
  //   });
  // }

  // console.log(data2);
  //   useEffect(() => {
  //     QRCode.toDataURL(itemCode.itemCode).then(setQr);
  //   }, [itemCode]);
  // const openLabelInNew = (
  //   names,
  //   grossWts,
  //   stoneWts,
  //   netWts,
  //   itemCodes,
  //   puritys
  // ) => {
  //   // console.log("hello");
  //   const data = {
  //     format: [26, 12],
  //     orientation: "landscape",
  //     name: names,
  //     grossWt: grossWts,
  //     stoneWt: stoneWts,
  //     netWt: netWts,
  //     itemCode: itemCodes,
  //     purity: puritys,
  //     fontSize: 5, //
  //     imageHeight: 8,
  //     imageWidth: 8,
  //   };
  //   const doc = new jsPDF({
  //     format: data.format,
  //     orientation: data.orientation,
  //   });
  //   doc.setFontSize(data.fontSize);
  //   doc.text(`${data.name.substr(0, 26)}`, 1, 3);
  //   doc.text(`G.Wt: ${data.grossWt}`, 1, 5);
  //   doc.text(`S.Wt: ${data.stoneWt}`, 1, 7);
  //   doc.text(`N.Wt: ${data.netWt}`, 1, 9);
  //   doc.text(`${data.itemCode}`, 12, 6);
  //   doc.text(`${data.purity}`, 12, 8);
  //   doc.addImage(qr, "JPEG", 17, 3, data.imageWidth, data.imageHeight);

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };
  console.log(updatedProducts);

  // NDDARBAR LABEL BELOW
  // const openLabelInNew = async (products) => {
  //   const doc = new jsPDF({
  //     format: [29, 12],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 6;
  //   const imageHeight = 7;
  //   const imageWidth = 7;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       stoneAmount,
  //       netWt,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       making_Fixed_Wastage,
  //       making_Percentage,
  //     } = products[i];

  //     // console.log("products", products);
  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }

  //     doc.setFontSize(fontSize);
  //     doc.setFont("helvetica", "bold");
  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.WT: ${parseFloat(grosswt).toFixed(3)}`, 3, 3);
  //       doc.text(`S.WT: ${parseFloat(stoneWeight).toFixed(3)}`, 3, 5.5);
  //       if (
  //         parseFloat(making_Percentage) !== 0 &&
  //         making_Percentage !== "" &&
  //         making_Fixed_Wastage !== 0 &&
  //         making_Fixed_Wastage !== ""
  //       ) {
  //         doc.text(
  //           `W.WT: ${(
  //             parseFloat(netWt) / parseFloat(making_Percentage) +
  //             parseFloat(making_Fixed_Wastage)
  //           ).toFixed(3)}`,
  //           3,
  //           7.5
  //         );
  //         doc.text(
  //           `N.WT: ${(
  //             parseFloat(netWt) +
  //             parseFloat(netWt / making_Percentage) +
  //             parseFloat(making_Fixed_Wastage)
  //           ).toFixed(3)}`,
  //           3,
  //           10
  //         );

  //         doc.text(`${itemCode}`, 18, 3);
  //         doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //         doc.text(`${purity}`, 18, 7.5);
  //         doc.text(`Pc:${pieces}`, 18, 10);
  //       } else if (
  //         parseFloat(making_Percentage) !== 0 &&
  //         making_Percentage !== ""
  //       ) {
  //         doc.text(
  //           `W.WT: ${(
  //             parseFloat(netWt) / parseFloat(making_Percentage)
  //           ).toFixed(3)}`,
  //           3,
  //           7.5
  //         );
  //         doc.text(
  //           `N.WT: ${(
  //             parseFloat(netWt) + parseFloat(netWt / making_Percentage)
  //           ).toFixed(3)}`,
  //           3,
  //           10
  //         );

  //         doc.text(`${itemCode}`, 18, 3);
  //         doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //         doc.text(`${purity}`, 18, 7.5);
  //         doc.text(`Pc:${pieces}`, 18, 10);
  //       } else if (making_Fixed_Wastage !== 0 && making_Fixed_Wastage !== "") {
  //         doc.text(
  //           `W.WT: ${parseFloat(making_Fixed_Wastage).toFixed(3)}`,
  //           3,
  //           7.5
  //         );
  //         doc.text(
  //           `N.WT: ${(
  //             parseFloat(making_Fixed_Wastage) + parseFloat(netWt)
  //           ).toFixed(3)}`,
  //           3,
  //           10
  //         );

  //         doc.text(`${itemCode}`, 18, 3);
  //         doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //         doc.text(`${purity}`, 18, 7.5);
  //         doc.text(`Pc:${pieces}`, 18, 10);
  //       } else {
  //         doc.text(`W.WT: N/A`, 3, 8);
  //         doc.text(`N.WT: ${netWt.toFixed(3)}`, 3, 10.5);

  //         doc.text(`${itemCode}`, 18, 3);
  //         doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //         doc.text(`${purity}`, 18, 7.5);
  //         doc.text(`Pc:${pieces}`, 18, 10);
  //       }
  //     } else {
  //       doc.text(`G.WT: ${grosswt.toFixed(3)}`, 3, 3);
  //       doc.text(`MRP: ${parseInt(mrp)}`, 3, 6);
  //       doc.text(`Pc:${pieces}`, 18, 9);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 18, 3);
  //       doc.text(`${purity}`, 18, 6);
  //     }
  //   }

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };

  // GHARES LABEL BELOW
  // const openLabelInNew = async (products) => {
  //   const doc = new jsPDF({
  //     format: [29, 12],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 6;
  //   const imageHeight = 7;
  //   const imageWidth = 7;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       stoneAmount,
  //       netWt,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       making_Fixed_Wastage,
  //       making_Percentage,
  //     } = products[i];

  //     // console.log("products", products);
  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }

  //     doc.setFontSize(fontSize);
  //     doc.setFont("helvetica", "bold");
  //     // {
  //     //   collection.length > 20
  //     //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
  //     //     : doc.text(`${collection}`, 1, 3);
  //     // }

  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.WT: ${parseFloat(grosswt).toFixed(3)}`, 3, 3);
  //       doc.text(`S.WT: ${parseFloat(stoneWeight).toFixed(3)}`, 3, 6);
  //       doc.text(`N.WT: ${parseFloat(netWt).toFixed(3)}`, 3, 9);

  //       doc.text(`${itemCode}`, 18, 3);
  //       // doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //       doc.text(`${purity}`, 18, 6);
  //       doc.text(`Pc:${pieces}`, 18, 9);
  //     } else {
  //       doc.text(`G.WT: ${grosswt.toFixed(3)}`, 3, 3);
  //       doc.text(`MRP: ${parseInt(mrp)}`, 3, 6);
  //       doc.text(`Pc:${pieces}`, 18, 9);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 18, 3);
  //       doc.text(`${purity}`, 18, 6);
  //     }

  //     // try {
  //     //   const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
  //     //   doc.addImage(qrCodeDataUrl, "JPEG", 1, 3, imageWidth, imageHeight);
  //     // } catch (error) {
  //     //   console.error(error);
  //     // }
  //   }

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };

  // MADANS LABEL BELOW
  const openLabelInNew = async (products) => {
    const doc = new jsPDF({
      // format: [26, 12],
      format: [28, 12],
      orientation: "landscape",
    });

    const fontSize = 6;
    const imageHeight = 7;
    const imageWidth = 7;

    for (let i = 0; i < products.length; i++) {
      const {
        collection,
        grosswt,
        stoneWeight,
        netWt,
        stoneAmount,
        itemCode,
        purity,
        mrp,
        product_No,
        pieces,
        description,
      } = products[i];

      if (i > 0) {
        doc.addPage(); // Add a new page for each product after the first one
      }

      doc.setFontSize(fontSize);
      // {
      //   collection.length > 20
      //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
      //     : doc.text(`${collection}`, 1, 3);
      // }

      if (mrp == 0 || mrp === "") {
        doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 4, 4);
        doc.text(`N.Wt: ${parseFloat(netWt).toFixed(3)}`, 4, 6);
        doc.text(`S.Wt: ${parseFloat(stoneWeight).toFixed(3)}`, 4, 8);
        doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 20, 8);
        doc.text(`Pcs:${pieces}`, 18, 2);
        // doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${itemCode}`, 4, 2);
        doc.text(`${purity}`, 22, 4);
        doc.setFontSize(5);
        const maxLineLength = 27;
        const descriptionLine1 = description.substring(0, maxLineLength);
        const descriptionLine2 = description.substring(
          maxLineLength,
          maxLineLength * 2
        );

        doc.text(descriptionLine1, 4, 10);
        doc.text(descriptionLine2, 4, 11.5);
      } else {
        doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 11, 5.5);
        doc.text(`MRP: ${parseFloat(mrp)}`, 11, 7.5);
        doc.text(`Pcs:${pieces}`, 18, 2);
        // doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${itemCode}`, 4, 3);
        doc.text(`${purity}`, 18, 11.5);
      }

      try {
        const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
        // doc.addImage(qrCodeDataUrl, "JPEG", 3, 3, imageWidth, imageHeight);
      } catch (error) {
        console.error(error);
      }
    }

    const pdfData = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(
      `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const formData = new FormData();

    formData.append("Product_Name", productName);
    formData.append("Category_id", categoryId);
    formData.append("Category_Name", categoryName);
    formData.append("ProductTypeId", parseInt(productTypeId));
    formData.append("CollectionId", parseInt(collectionId));
    formData.append("PartyTypeId", parseInt(partyId));
    // formData.append("Party_Details", partyName);
    formData.append("purity", purityName);
    formData.append("PurityId", purityId);
    formData.append("BoxId", parseInt(boxId));
    formData.append("MRP", parseFloat(mrp));
    formData.append("Quantity", parseInt(quantity));
    formData.append("grosswt", parseFloat(grosswt).toFixed(3));
    formData.append("StoneWeight", stoneWeight);
    formData.append("NetWt", parseFloat(netWt).toFixed(3));
    formData.append("Entryby_Staff_id", parseInt(Entryby_Staff_id));
    formData.append("Product_No", partyName);
    formData.append("Product_Code", productCode);
    formData.append("Pieces", parseInt(pieces));
    formData.append("HUIDCode", huid);
    formData.append("Size", size);
    formData.append("collection", collectionName);
    formData.append("occasion", occasion);
    formData.append("gender", gender);
    formData.append("description", description);
    formData.append("Making_Fixed_Amt", making_Fixed_Amt);
    formData.append("Making_per_gram", making_per_gram);
    formData.append("Making_Percentage", making_Percentage);
    formData.append("Making_Fixed_Wastage", making_Fixed_Wastage);
    formData.append("StoneAmount", stoneAmount);
    formData.append("Featured", featured);
    formData.append("Itemtype", productTypeName);
    formData.append("Product_type", productTypeName);
    // formData.append("BarcodeNumber", "");
    formData.append("Images", "");
    // selectedFiles.forEach((file) => {
    //   formData.append("Images", file);
    // });
    formData.append("ImageList1", "");
    formData.append("ImageList2", "");
    formData.append("ImageList3", "");
    formData.append("ImageList4", "");
    formData.append("ImageList5", "");
    console.log(formData);
    try {
      const response = await fetch(a8, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(formData),
        body: formData,
      });
      const data = await response.json();

      if (response.ok) {
        setAddedProducts(data.data);
        setLoading(false);
        console.log("added", data);
        const allItemCodes = data.data.map((product) => ({
          ItemCode: product.itemCode,
        }));
        setAllItemCodesArray(allItemCodes);
        setDeleteAll(true);
        // setData(data.data);
        // updateImages();
        // alert("added");
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files.");
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };
  useEffect(() => {
    console.log("allItemCodesArray", allItemCodesArray);
  }, [allItemCodesArray]);

  console.log("allItemCodesArray outside useEffect", allItemCodesArray);

  // console.log(addedProducts);
  // console.log(addedProducts);
  // const updatedetailsBox = async (productInEdit) => {
  //   const formData = [
  //     {
  //       Id: productInEdit,
  //       Product_Name: productName,
  //       Category_id: categoryId,
  //       Category_Name: categoryName,
  //       ProductTypeId: productTypeId,
  //       PartyTypeId: parseInt(partyTypeId),
  //       CollectionId: parseInt(collectionId),
  //       purity: purity,
  //       BoxId: parseInt(boxId),
  //       MRP: parseInt(mrp),
  //       Quantity: parseInt(quantity),
  //       grosswt: parseInt(grosswt),
  //       StoneWeight: stoneWeight,
  //       NetWt: parseInt(netWt),
  //       Entryby_Staff_id: parseInt(Entryby_Staff_id),
  //       Product_No: "Product_No",
  //       Product_Code: productCode,
  //       Pieces: parseInt(pieces),
  //       HUIDCode: huid,
  //       Size: size,
  //       collection: collectionName,
  //       occasion: occasion,
  //       gender: gender,
  //       description: description,
  //       Makingcharges: makingCharges,
  //       Makingtype: makingType,
  //       StoneAmount: stoneAmount,
  //       Featured: featured,
  //       Itemtype: "ItemType",
  //       ItemCode: itemCode,
  //       ImageList1: "",
  //       ImageList2: "",
  //       ImageList3: "",
  //       ImageList4: "",
  //       ImageList5: "",
  //       images: productInEditImages,
  //       // product_tye: productTypeName,
  //     },
  //   ];
  //   // const formData = new FormData();
  //   // formData.append("Product_id", productId);
  //   // formData.append("FieldName", parameter);
  //   // formData.append("FieldValue", formValue);
  //   // console.log(JSON.stringify(formData));
  //   try {
  //     const response = await fetch(a31, {
  //       //   method: "POST",
  //       //   body: formData,
  //       // });
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });
  //     const rcvdData = await response.json();
  //     // if (data.status == "success") {
  //     console.log(rcvdData);
  //     //   alert(`${parameter} Changed Successfully`);
  //     setItemCode(rcvdData.data[0]);
  //     QRCode.toDataURL(itemCode).then(setQr);
  //     openLabelInNew(
  //       rcvdData.data[0].product_Name,
  //       rcvdData.data[0].grosswt,
  //       rcvdData.data[0].stoneWeight,
  //       rcvdData.data[0].netWt,
  //       rcvdData.data[0].itemCode,
  //       rcvdData.data[0].purity
  //     );
  //     //   setFormValue("");
  //     //   setPopup(false);
  //     // alert(`${parameter} Changed Successfully`);
  //     // } else {
  //     //   console.error(data.error);
  //     // }
  //     // setLoading(false);
  //     // setPopup(false);

  //     //   updateStaffId();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // console.log(updatedProducts);
  // const handleInputChange = (e, productId, property) => {
  //   const updatedProducts = addedProducts.map((product) => {
  //     if (product.id === productId) {
  //       // Update the specific property in the product object
  //       const updatedProduct = {
  //         ...product,
  //         [property]: e.target.value,
  //       };

  //       // If property is "stoneWt", update "netWt" accordingly
  //       if (property === "StoneWeight") {
  //         const grossWt = parseFloat(updatedProduct.grossWt);
  //         const stoneWt = parseFloat(updatedProduct.stoneWeight);

  //         if (!isNaN(grossWt) && !isNaN(stoneWeight)) {
  //           updatedProduct.netWt = grossWt - stoneWeight;
  //         } else {
  //           // If grossWt or stoneWt is not a valid number, set netWt to 0 or some other appropriate value
  //           updatedProduct.netWt = 0;
  //         }
  //       }

  //       return updatedProduct;
  //     }
  //     return product;
  //   });

  //   setAddedProducts(updatedProducts);
  // };

  // OLD CODE

  // const handleInputChange = (e, productId, property) => {
  //   const updatedProducts = addedProducts.map((product) => {
  //     if (product.id === productId) {
  //       // Update the specific property in the product object
  //       return {
  //         ...product,
  //         [property]: e.target.value,
  //       };
  //     }
  //     return product;
  //   });

  //   setAddedProducts(updatedProducts);
  // };

  // OLD CODE

  // ... (previous code)

  const handleInputChange = (e, productId, property) => {
    const barcodeInput = document.getElementById("barcodeNumberInput");
    barcodeInput.style.setProperty("color", "black");
    const { value } = e.target;
    // setBarCodeAlert(false);
    const updatedProducts = addedProducts.map((product) => {
      if (product.id === productId) {
        // Parse properties to numbers or set them as 0 if the value is empty or invalid
        const grosswt = parseFloat(product.grosswt) || 0;
        const stoneWeight = parseFloat(product.stoneWeight) || 0;
        const netWt = parseFloat(product.netWt) || 0;

        // Update the specific property in the product object
        let updatedProduct = { ...product, [property]: value };

        if (property === "barcodeNumber") {
          // Convert the barcode number to uppercase before doing the comparison
          const barcodeValue = value.toUpperCase();
          updatedProduct.barcodeNumber = barcodeValue; // Set the barcodeNumber property to uppercase

          // Find a matching product in the rifdData array
          const matchingProduct = rifdData.find(
            (item) => item.barcodeNumber === barcodeValue
          );

          if (matchingProduct) {
            updatedProduct.tid = matchingProduct.tid;
          } else {
            // If no matching product found, set 'tid' to null or some default value
            updatedProduct.tid = null; // or any default value you want
            // setBarCodeAlert(true);
          }
        }

        // If 'grosswt' is changed, calculate 'netWt'
        if (property === "grosswt" && !isNaN(value)) {
          updatedProduct.netWt = (parseFloat(value) - stoneWeight).toFixed(3);
        }

        // If 'stoneWeight' is changed, calculate 'netWt'
        if (property === "stoneWeight" && !isNaN(value)) {
          updatedProduct.netWt = (grosswt - parseFloat(value)).toFixed(3);
        }

        // If 'netWt' is changed, calculate 'grosswt' and 'stoneWeight'
        if (property === "netWt" && !isNaN(value)) {
          updatedProduct.grosswt = (parseFloat(value) + stoneWeight).toFixed(3);
          updatedProduct.stoneWeight = (grosswt - parseFloat(value)).toFixed(3);
        }

        return updatedProduct;
      }
      return product;
    });

    setAddedProducts(updatedProducts);
  };

  // ... (rest of the code)

  const playTimer = () => {
    setTimeout(() => {
      setGoldAlert(false), setBarCodeAlert(false);
    }, 2000);
  };

  const handleEditProducts = async () => {
    setLoading(true);
    console.log(addedProducts, "save");
    try {
      // Validate 'grosswt' for all products
      const hasInvalidGrossWt = addedProducts.some(
        (product) =>
          (product.grosswt === "" && product.category_Name === "Gold") ||
          (parseFloat(product.grosswt) === 0 &&
            product.category_Name === "Gold")
      );

      const hasMissingBarcodeAndTid = addedProducts.some((product) => {
        if (product.barcodeNumber && product.barcodeNumber.length !== 0) {
          // Barcode is not empty or null, so check if tid is missing
          return product.tid === null || product.tid === "";
        }
        // Barcode is either empty or null, so no need to check tid
        return false;
      });

      if (hasInvalidGrossWt) {
        setLoading(false);
        setGoldAlert(true);
        playTimer();
      } else if (hasMissingBarcodeAndTid) {
        setLoading(false);
        setBarCodeAlert(true);
        playTimer();
      } else {
        // Convert grosswt, stoneWeight, and netWt to strings before sending
        const updatedProductsString = addedProducts.map((product) => ({
          ...product,
          grosswt: product.grosswt.toString(),
          stoneWeight: product.stoneWeight.toString(),
          netWt: product.netWt.toString(),
        }));

        // Send the updated products to the edit API endpoint
        const response = await fetch(a31, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedProductsString),
        });
        console.log(updatedProductsString);
        const rcvdData = await response.json();
        console.log("rcvdData", rcvdData);
        //       openLabelInNew(rcvdData.data);
        //       console.log("addedProducts", addedProducts);
        //       console.log("updatedProducts", updatedProducts);
        //       // setUpdatedProducts(rcvdData.data);
        //       setLoading(false);
        //     }
        //   } catch (error) {
        //     alert(error);
        //     console.error(error);
        //     setLoading(false);
        //   }
        // };
        if (rcvdData.status === "error") {
          setLoading(false);
          alert(rcvdData.message); // Show error message
          const productsWithErrors = addedProducts.map((product) =>
            product.barcodeNumber === rcvdData.errorBarcode
              ? { ...product, hasError: true }
              : product
          );
          setAddedProducts(productsWithErrors);
          console.log("rcvdDataErrorAdded", addedProducts);
        } else {
          openLabelInNew(rcvdData.data);
          console.log("addedProducts", addedProducts);
          console.log("updatedProducts", updatedProducts);
          setLoading(false);
        }
      }
    } catch (error) {
      alert(error);
      console.error(error);
      setLoading(false);
    }
  };
  // const handleEditProducts = async () => {
  //   setLoading(true);
  //   console.log(addedProducts, "save");
  //   try {
  //     // Convert grosswt, stoneWeight, and netWt to strings before sending
  //     const updatedProductsString = addedProducts.map((product) => ({
  //       ...product,
  //       grosswt: product.grosswt.toString(),
  //       stoneWeight: product.stoneWeight.toString(),
  //       netWt: product.netWt.toString(),
  //     }));

  //     // Send the updated products to the edit API endpoint
  //     const response = await fetch(a31, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(updatedProductsString),
  //     });

  //     const rcvdData = await response.json();
  //     console.log("rcvdData", rcvdData);

  //     // Assuming rcvdData contains the updated product data
  //     // Do something with the received data here

  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };

  console.log("2addedProducts", addedProducts);
  const filteredProducts = productTypeData.filter(
    (product) => product.category_id == categoryId
  );
  const filteredCollection = collectionTypeData.filter(
    (product) => product.productType == productTypeName
  );
  const filteredPurity = purityData.filter(
    (product) => product.category == categoryName
  );
  const filteredBoxes = boxData.filter(
    (product) => product.productName == productTypeName
  );

  const handleCheckboxChange = (productId, itemCode) => {
    let updatedCheckedProducts = [...checkedProducts];
    let updatedSelectedItemCodes = [...selectedItemCodes];

    if (updatedCheckedProducts.includes(productId)) {
      updatedCheckedProducts = updatedCheckedProducts.filter(
        (id) => id !== productId
      );
      updatedSelectedItemCodes = updatedSelectedItemCodes.filter(
        (code) => code !== itemCode
      );
    } else {
      updatedCheckedProducts.push(productId);
      updatedSelectedItemCodes.push(itemCode);
    }

    if (updatedCheckedProducts.length > 0) {
      setDeleteSelected(true);
    } else {
      setDeleteSelected(false);
    }

    setCheckedProducts(updatedCheckedProducts);
    setSelectedItemCodes(updatedSelectedItemCodes);
  };

  const selectedItems = selectedItemCodes.map((itemCode) => ({
    ItemCode: itemCode,
  }));

  console.log("checkedProducts", checkedProducts);
  console.log("selectedItemCodes", selectedItemCodes);
  console.log("selectedItems", selectedItems);

  const deleteAllProducts = async (itemsToDelete) => {
    try {
      const response = await fetch(a47, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(itemsToDelete),
      });

      const rcvdData = await response.json();
      console.log("AllItemsDeleted", rcvdData);
      console.log("Responsee:", rcvdData.message);
      if (rcvdData.status == "Success") {
        // Deletion was successful
        // console.log("Item deleted successfully:", response.message);
        alert(rcvdData.message);
        setSelectedItemCodes([]);
        setCheckedProducts([]);
        // You can show an alert or notification here
        // alert(data.message);
        if (itemsToDelete == allItemCodesArray) {
          setAddedProducts([]);
        } else {
          const updatedAddedProducts = addedProducts.filter((product) => {
            return !itemsToDelete.some(
              (item) => item.ItemCode === product.itemCode
            );
          });
          setAddedProducts(updatedAddedProducts);
        }
        setDeleteAll(false);
        setDeleteSelected(false);
      } else {
        // Handle the case where deletion failed
        console.error("Failed to delete item:", response.message);

        // You can show an error message to the user
        alert("Failed to delete item: " + response.message);
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);

      // Show an error message to the user
      // alert("An error occurred while deleting the item.");
    }
  };

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      {goldAlert ? (
        <AlertMessage
          type="error"
          message="Gross Wt of Gold could not be zero"
        />
      ) : null}
      {barCodeAlert ? (
        <AlertMessage
          type="error"
          message="Sorry, Please enter a correct Barcode"
        />
      ) : null}
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        {loadingAdd ? (
          <div className="adminAddCategoryMainbox addProductMain">
            <form onSubmit={handleSubmit}>
              <h3 style={{ marginBottom: "3rem", width: "95%" }}>
                ADD BULK STOCK
              </h3>
              <div style={{ width: "92%" }} className="addProductDetailsBox">
                <label htmlFor="category">
                  <strong>SUPPLIER</strong>
                </label>
                <select
                  id="category"
                  required="required"
                  value={partyTypeId}
                  onChange={(e) => setPartyTypeId(e.target.value)}
                >
                  <option value="">Select Party / Karigar Name</option>
                  {partyData.map((x) => {
                    return (
                      <option value={`${parseInt(x.id)},${x.supplier_code}`}>
                        {x.supplier_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="bulkStockAddProductDetailsBox">
                <div className="bulkStockAddProductDetailsItem">
                  <label style={{ margin: 0 }}>Category</label>
                  <select
                    id="category"
                    required="required"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">Category</option>
                    {categoriesData.map((x) => {
                      return (
                        <option value={`${x.id},${x.name}`}>{x.name}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  <label htmlFor="productTypeId" style={{ margin: 0 }}>
                    Product
                  </label>

                  <select
                    id="productTypeId"
                    required="required"
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                  >
                    <option value="">Product Type</option>
                    {filteredProducts.map((x) => {
                      return (
                        <option value={`${parseInt(x.id)},${x.productTitle}`}>
                          {x.productTitle}
                        </option>
                      );
                    })}
                    {/* {productTypeData.map((x) => {
                    return (
                      // <option value={parseInt(x.id)}>{x.productTitle}</option>
                      <option value={`${parseInt(x.id)},${x.productTitle}`}>
                        {x.productTitle}
                      </option>
                    );
                  })} */}
                  </select>
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  {/* <label style={{ margin: 0 }}>Brand</label> */}
                  <label style={{ margin: 0 }}>Collection</label>
                  <select
                    id="collection"
                    required="required"
                    value={collection}
                    onChange={(e) => setCollection(e.target.value)}
                  >
                    <option value="">Colletion</option>
                    {/* <option value="">Brand</option> */}
                    {filteredCollection.map((x) => {
                      return (
                        <option
                          value={`${parseInt(x.id)},${x.collection_Name}`}
                        >
                          {x.collection_Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  {/* <label style={{ margin: 0 }}>Pattern</label> */}
                  <label style={{ margin: 0 }}>Purity</label>
                  <select
                    id="purity"
                    required="required"
                    value={purity}
                    onChange={(e) => setPurity(e.target.value)}
                  >
                    {/* <option value="">Pattern</option> */}
                    <option value="">Purity</option>
                    {filteredPurity.map((x) => {
                      return (
                        <option value={`${parseInt(x.id)},${x.label}`}>
                          {x.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  <label htmlFor="boxId" style={{ margin: 0 }}>
                    Box
                  </label>
                  <select
                    id="boxId"
                    // required="required"
                    value={boxId}
                    onChange={(e) => setBoxId(e.target.value)}
                  >
                    <option value="">Box</option>
                    {filteredBoxes.map((x) => {
                      return (
                        <option value={parseInt(x.id)}>{x.boxName}</option>
                      );
                    })}
                  </select>
                </div>
                {/* <div className="bulkStockAddProductDetailsItem">
                  <label style={{ margin: 0 }}>P Name</label>
                  <input
                    type="text"
                    required="required"
                    value={productName}
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                  />
                </div> */}

                <div className="bulkStockAddProductDetailsItem">
                  <label htmlFor="grosswt" style={{ margin: 0 }}>
                    G.Wt
                  </label>
                  {/* <select
                  id="category"
                  required="required"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">G.Wt</option>
                </select> */}
                  <input
                    type="number"
                    id="grosswt"
                    required="required"
                    value={grosswt}
                    onChange={(e) => {
                      setGrosswt(e.target.value),
                        setNetWt(
                          parseFloat(e.target.value) - parseFloat(stoneWeight)
                        );
                    }}
                  />
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  <label htmlFor="stoneWeight" style={{ margin: 0 }}>
                    St.Wt
                  </label>
                  {/* <select
                  id="category"
                  required="required"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">St.Wt</option>
                </select> */}
                  <input
                    type="number"
                    id="stoneWeight"
                    value={stoneWeight}
                    onChange={(e) => {
                      setStoneWeight(e.target.value),
                        setNetWt(
                          parseFloat(grosswt) - parseFloat(e.target.value)
                        );
                      // setGrosswt(
                      //   parseFloat(netWt) + parseFloat(e.target.value)
                      // );
                    }}
                  />
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  <label htmlFor="netWt" style={{ margin: 0 }}>
                    Net.Wt
                  </label>
                  {/* <select
                  id="category"
                  required="required"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">Net.Wt</option>
                </select> */}
                  <input
                    type="number"
                    id="netWt"
                    value={netWt}
                    onChange={(e) => {
                      setNetWt(e.target.value),
                        setGrosswt(
                          parseFloat(e.target.value) + parseFloat(stoneWeight)
                        );
                    }}
                  />
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  <label style={{ margin: 0 }}>MRP</label>
                  <input
                    type="number"
                    value={mrp}
                    onChange={(e) => {
                      setMRP(e.target.value);
                    }}
                  />
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  <label style={{ margin: 0 }}>Quantity</label>
                  <input
                    required="required"
                    type="number"
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  <label htmlFor="name" style={{ margin: 0 }}>
                    P.Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                  />
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  <label htmlFor="description" style={{ margin: 0 }}>
                    P.Description
                  </label>
                  <input
                    style={{ width: "2fr" }}
                    type="text"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="bulkStockAddProductDetailsItem">
                  <label style={{ margin: 0 }}>Gender</label>
                  <select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Unisex">Unisex</option>
                    <option value="Kids">Kids</option>
                  </select>
                </div>
                {/* <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0, cursor: "pointer" }}>
                  Images {`${selectedFiles.length}`}
                  <BsImages
                    style={{ margin: "1.2rem", marginInline: "1rem" }}
                    size={"2.5rem"}
                  />
                  <input
                    id="images"
                    style={{ display: "none" }}
                    type="file"
                    multiple
                    onChange={handleFileInputChange}
                  />
                </label>
              </div> */}

                <button
                  type="submit"
                  style={{ width: "100px", marginInline: "0" }}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div
            style={{ height: "50vh", marginBottom: "1rem" }}
            // className={loadingAdd == true ? "loading" : "none"}
            className="loading"
          >
            <InfinitySpin
              // className={loadingAdd == true ? "loading" : "none"}
              className="loading"
              width="150"
              color="#4fa94d"
            />
          </div>
        )}
        {/* <div>
          <h1>Table Component</h1>
          <table style={{ margin: "3rem" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>P Name</th>
                <th>Gross Wt</th>
                <th>Stone Wt</th>
                <th>Net Wt</th>
              </tr>
            </thead>
             <tbody>{renderTableRows()}</tbody> 
          </table>
        </div> */}
        <div
          style={{ height: "100px", marginBottom: "1rem" }}
          className={loading == true ? "loading" : "none"}
        >
          <InfinitySpin
            className={loading == true ? "loading" : "none"}
            width="150"
            color="#4fa94d"
          />
        </div>
        <h3 style={{ margin: "0.5rem 0" }}>Added Products</h3>
        {/* <form onSubmit={updatedetailsBox}> */}
        <div
          className="bulkProductAddingTableMain"
          style={{ margin: "1.5rem", overflowX: "auto" }}
        >
          <table>
            <thead>
              <tr>
                <th style={{ whiteSpace: "nowrap" }}>Delete Product</th>
                <th>Product Type</th>
                <th>Collection</th>
                {/* <th>Brand</th> */}
                <th>Purity</th>
                {/* <th>Pattern</th> */}
                <th>Label</th>
                <th>Barcode Number</th>
                <th>TID</th>
                <th>Product name</th>
                <th>HUID Code</th>
                <th>GrossWt</th>
                <th>StoneWt</th>
                <th>NetWt</th>
                <th>Stone Amount</th>
                <th>Making Per Gram</th>
                <th>Making Percentage</th>
                <th>Fixed Making</th>
                <th>Fixed Wastage</th>
                <th>Pieces</th>
                <th>Size</th>
                <th>MRP</th>
                <th>Description</th>
                <th>Occassion</th>
                <th>Gender</th>
                <th>Online Status</th>
              </tr>
            </thead>
            <tbody>
              {addedProducts.map((x) => (
                // <tr key={x.Customer_id}>

                <tr key={x.id}>
                  <td>
                    <input
                      style={{ width: "25px", height: "25px", color: "red" }}
                      type="checkbox"
                      checked={checkedProducts.includes(x.id)}
                      onChange={() => handleCheckboxChange(x.id, x.itemCode)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.product_type}
                      // value={x.product_type}
                      // onChange={(e) => handleInputChange(e, x.id, "Product_type")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.collection}
                      value={x.collection}
                      // onChange={(e) => handleInputChange(e, x.id, "collection")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.purity}
                      value={x.purity}
                      // onChange={() => {
                      //   setPurity(x.purity);
                      // }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.itemCode}
                      value={x.itemCode}
                      //   onChange={() => {
                      //     setItemCode(x.itemCode);
                      //   }}
                    />
                  </td>
                  <td>
                    <input
                      id="barcodeNumberInput"
                      type="text"
                      placeholder={x.BarcodeNumber}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "barcodeNumber")
                      }
                      style={{ color: x.hasError ? "red" : "black" }}
                      //     setItemCode(x.itemCode);
                      //   }}
                    />
                  </td>
                  <td>
                    <input
                      style={{ cursor: "not-allowed" }}
                      type="text"
                      placeholder={x.tid}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.product_Name}
                      // value={x.product_Name}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Product_Name")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      maxLength={6}
                      placeholder={x.huidCode}
                      onChange={(e) => handleInputChange(e, x.id, "huidCode")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.grosswt}
                      onChange={(e) => handleInputChange(e, x.id, "grosswt")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.stoneWeight}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "stoneWeight")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.netWt}
                      onChange={(e) => handleInputChange(e, x.id, "netWt")}
                    />
                  </td>

                  <td>
                    <input
                      type="number"
                      placeholder={x.stoneAmount}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "StoneAmount")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.making_per_gram}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Making_per_gram")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.making_Percentage}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Making_Percentage")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.making_Fixed_Amt}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Making_Fixed_Amt")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.making_Fixed_Wastage}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Making_Fixed_Wastage")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.pieces}
                      onChange={(e) => handleInputChange(e, x.id, "pieces")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.size}
                      onChange={(e) => handleInputChange(e, x.id, "Size")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.mrp}
                      onChange={(e) => handleInputChange(e, x.id, "mrp")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.description}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "description")
                      }
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      placeholder={x.occasion}
                      onChange={(e) => handleInputChange(e, x.id, "occasion")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.gender}
                      onChange={(e) => handleInputChange(e, x.id, "gender")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.featured}
                      onChange={(e) => handleInputChange(e, x.id, "Featured")}
                    />
                  </td>

                  {/* <td>
                    <button
                      type="submit"
                      onClick={() => {
                        setItemCode(x.itemCode);
                        setProductInEditImages(x.images);
                        setProductInEdit(x);
                        {
                          console.log(x.id);
                        }
                        updatedetailsBox(x.id);
                      }}
                    >
                      Update
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bulkProductAddingTableMain">
          {deleteSelected ? (
            <button
              onClick={() => deleteAllProducts(selectedItems)}
              className="bulkProductAddDeleteButton"
            >
              Delete Selected
            </button>
          ) : null}
          <button
            style={{ cursor: "pointer" }}
            // onClick={handleEditProducts}>
            onClick={handleEditProducts}
          >
            Save All
          </button>

          <Link to="/admin-labelled-list">
            <button style={{ cursor: "pointer" }}>Labelled List</button>
          </Link>
          <Link to="/admininvoice">
            <button style={{ cursor: "pointer" }}>To Invoice</button>
          </Link>
          <button
            onClick={() => {
              setAddedProducts([]);
              setSelectedFiles([]);
            }}
          >
            New Item
          </button>
          {deleteAll ? (
            <button
              onClick={() => deleteAllProducts(allItemCodesArray)}
              className="bulkProductAddDeleteButton"
              style={{ backgroundColor: "#c14456" }}
            >
              Delete All
            </button>
          ) : null}
        </div>
        {/* </form> */}
      </div>
    </div>
  );
}
