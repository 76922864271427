import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
import AdminNavbar from "./AdminNavbar";
import { a41 } from "../../../Api/RootApiPath";
import { fetchAllProducts } from "../../../../redux/action/Actions";

export default function FetchAllProducts() {
  const [loading, setLoading] = useState(false);
  const [selectBranch, setSelectBranch] = useState("Branch 2"); // Default value
  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  const Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    // Update selectBranch based on Entryby_Staff_id
    if (Entryby_Staff_id === 1) {
      setSelectBranch("Home");
    } else if (Entryby_Staff_id === 2) {
      setSelectBranch("Branch 2");
    }
  }, [Entryby_Staff_id]);

  const dispatch = useDispatch();

  const fetchAllProductsData = async () => {
    setLoading(true);
    try {
      const response = await fetch(a41);
      const data = await response.json();
      console.log(data);
      // const branchProducts = data.data.filter(
      //   (x) => x.branchName === selectBranch
      // );
      const branchProducts = data.data;
      const productsWithSerial = branchProducts.map((product, index) => ({
        ...product,
        serialNumber: index + 1,
      }));
      dispatch(fetchAllProducts(productsWithSerial.reverse()));
      alert("Updated");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);
    }
  };
  const products = useSelector((state) => state.reducer7.products);
  console.log(products);

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        <h2 style={{ margin: "2rem 0" }}>Fetch All Products</h2>
        <div className={loading ? "loading" : "none"}>
          <InfinitySpin width="200" color="#4fa94d" />
        </div>
        <div className="bulkProductAddingTableMain">
          <button style={{ cursor: "pointer" }} onClick={fetchAllProductsData}>
            Refresh
          </button>
        </div>
      </div>
    </div>
  );
}
